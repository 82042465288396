<template>
  <!-- 商品链接 -->
  <div class="goodsLink">
    <div class="top">
      <el-cascader
        placeholder="请选择"
        :options="goodsClassArr"
        :props="{ checkStrictly: true, label: 'name', value: 'id', children: '_child' }"
        clearable
        @change="goodsClassChange"
      ></el-cascader>
      <el-input @input="changeContent" placeholder="搜索商品名称" v-model="searchContent">
        <template #prefix>
          <i class="el-input__icon el-icon-search"></i>
        </template>
      </el-input>
    </div>
    <div class="bot" @scroll="lazyLoad">
      <div v-for="(item, index) in goodsList" :key="index" class="goodsItem">
        <div class="left">
          <div>
            <img :src="item.goods_picture" alt="" />
          </div>
          <div class="leftR">
            <div class="name">{{ item.goods_name }}</div>
            <div class="price">¥ {{ item.goods_price }}</div>
          </div>
        </div>
        <div class="rightBtn" @click="sendGoods(item)">发送</div>
        <div class="xian"></div>
      </div>
      <div v-if="Math.ceil(total / rows) < page" class="WaterMark">
        <img src="@/assets/images/WaterMark.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, ref } from 'vue'
import { post } from '@/util/axios'
import { api } from '@/util/api'
export default {
  props: {
    userInfo: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, content) {
    const data = reactive({
      // 商品分类分组
      goodsClassArr: [],
      // 被选中的分组
      activeClass: 0,
      // 搜索内容
      searchContent: '',
      // 搜索出来的商品链接
      goodsList: [],
      page: 1,
      rows: 15,
      total: 0
    })
    const classId = ref(0)
    const GoodsClass = ref([])
    post(api.getGoodsClass).then((res) => {
      if (res.code == 0) {
        data.goodsClassArr = res.result
      }
    })
    const getGoodsList = (status) => {
      let obj = {
        page: data.page,
        rows: data.rows,
        classify_id: classId.value ? classId.value : 0,
        goods_tag: data.searchContent
      }
      post(api.getGoodsList, obj).then((res) => {
        if (res.code === 0) {
          if (status) {
            data.goodsList = data.goodsList.concat(res.result.list)
          } else {
            data.goodsList = res.result.list
          }
          data.total = res.result.total_number
        }
      })
    }
    getGoodsList()
    // 选中的商品分类发生改变时
    const goodsClassChange = (val) => {
      if (!val) {
        classId.value = 0
      } else {
        classId.value = val[val.length - 1]
      }
      data.page = 1
      getGoodsList()
    }
    // 滑动到底部追加商品
    const lazyLoad = (e) => {
      // 获取距离顶部的距离
      let top = e.target.scrollTop
      // 获取可视区的高度
      let windowHeight = e.target.clientHeight
      // 获取滚动条的总高度
      let scrollHeight = e.target.scrollHeight
      if (top + windowHeight >= scrollHeight) {
        // 把距离顶部的距离加上可视区域的高度 等于或者大于滚动条的总高度就是到达底部
        data.page++
        if (Math.ceil(data.total / data.rows) < data.page) return
        getGoodsList(1)
      }
    }
    // 输入框变化
    const changeContent = () => {
      data.page = 1
      getGoodsList()
    }
    // 点击发送商品
    const sendGoods = (item) => {
      content.emit('sendGoodsMsg', 'miniprogrampage', item)
    }
    return {
      ...toRefs(data),
      GoodsClass,
      goodsClassChange,
      lazyLoad,
      changeContent,
      sendGoods
    }
  }
}
</script>

<style lang="less" scoped>
.goodsLink {
  height: 100%;
  padding-top: 14px;
  .top {
    display: flex;
    padding: 0 27px;
    :deep(.el-cascader) {
      width: 214px !important;
      margin-right: 20px;
      :deep(.el-input .el-input__inner:focus, .el-cascader .el-input.is-focus .el-input__inner) {
        border-color: #f3f3f3 !important;
      }
    }
    .el-input {
      height: 38px;
      border: 1px solid #f3f3f3 !important;
      border-radius: 4px !important;
      :deep(input) {
        border-color: #f3f3f3 !important;
      }
    }
  }
  .bot {
    height: calc(100% - 40px);
    overflow: hidden;
    overflow-y: auto;
    .goodsItem {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 23px 27px;
      position: relative;
      &:hover {
        background-color: #f0f0f0;
      }
      .xian {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: #f2f2f2;
        margin: 0 27px;
      }
      .left {
        display: flex;
        img {
          width: 55px;
          height: 55px;
          object-fit: cover;
          border-radius: 2px;
        }
        .leftR {
          margin-left: 11px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .name {
            width: 165px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .price {
            color: #dc0005;
          }
        }
      }
      .rightBtn {
        width: 45px;
        height: 25px;
        text-align: center;
        line-height: 24px;
        border: 1px solid #d7d7d7;
        border-radius: 2px;
        color: #666666;
        user-select: none;
        cursor: pointer;
        align-self: flex-end;
        &:hover {
          background-color: #1467ff;
          color: #fff;
        }
      }
    }
  }
}
.WaterMark {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}
</style>
