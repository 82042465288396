<template>
    <div class="bigBox">
        <el-input placeholder="搜索聊天记录" v-model="searchRecord">
            <template #prefix>
                <i class="el-input__icon el-icon-search"></i>
            </template>
        </el-input>
        <div class="recordList">
            <div class="listItem" v-for="(item, index) in chatList" :key="index">
                <div>
                    <img :src="item.headimg" alt="" />
                </div>
                <div class="right">
                    <div class="rightTop">
                        <div>{{ item.name }}</div>
                        <div>{{ item.time }}</div>
                    </div>
                    <div v-if="item.type === 0" class="goodsInfo">
                        <div class="goodsLeft">
                            <img :src="item.goodsInfo.img" alt="" />
                        </div>
                        <div class="goodsRight">
                            <div class="goodsName">{{ item.goodsInfo.goodsName }}</div>
                            <div class="goodsPrice">¥ {{ item.goodsInfo.price }}</div>
                        </div>
                    </div>
                    <pre v-if="item.type === 1">{{ item.content }}</pre>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, toRefs, ref } from 'vue'
export default {
    setup() {
        const data = reactive({
            searchRecord: '',
            chatList: [
                {
                    type: 0,
                    name: '猪猪',
                    time: '16:00',
                    headimg:
                        'https://boweisou.oss-cn-shenzhen.aliyuncs.com/customerSystem/defaultHeader.png',
                    goodsInfo: {
                        img: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/customerSystem/defaultHeader.png',
                        goodsName: '胡歌胡歌胡歌胡歌胡歌',
                        price: 9999999.99
                    }
                },
                {
                    time: '16:00',
                    headimg:
                        'https://boweisou.oss-cn-shenzhen.aliyuncs.com/customerSystem/defaultHeader.png',
                    type: 1,
                    name: '客服丫丫',
                    content: '山东，本地产当季水果，百分之百大果，品质保障！'
                },
                {
                    time: '16:00',
                    headimg:
                        'https://boweisou.oss-cn-shenzhen.aliyuncs.com/customerSystem/defaultHeader.png',
                    type: 1,
                    name: '客服丫丫',
                    content: `回复1，查看【店铺发货时效】
回复2，查看【物流不动/不发货怎么办】
回复3，查看【是否包邮到家】
回复4，查看【店铺发什么快递】
回复5，查看【七天无理由退回】`
                }
            ]
        })
        return {
            ...toRefs(data)
        }
    }
}
</script>

<style lang="less" scoped>
.bigBox {
    height: 100%;
    .el-input {
        width: 478px;
        margin: 16px 26px 0px 36px;
        height: 38px;
        border: 1px solid #f3f3f3 !important;
        border-radius: 4px !important;
        :deep(input) {
            border-color: #f3f3f3 !important;
        }
    }
    .recordList {
        height: calc(100% - 54px);
        overflow: hidden;
        overflow-y: auto;
        padding-top: 10px;
        .listItem {
            padding: 20px 36px;
            display: flex;
            cursor: pointer;
            &:hover {
                background-color: #f0f0f0;
            }
            img {
                width: 38px;
                height: 38px;
                border-radius: 50%;
            }
            .right {
                width: 100%;
                margin-left: 10px;
                .rightTop {
                    color: #999999;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 5px;
                }
                .goodsInfo {
                    display: flex;
                    img {
                        width: 43px;
                        height: 43px;
                        border-radius: 4px;
                    }
                    .goodsRight {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        margin-left: 5px;
                        .goodsName {
                            width: 165px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        .goodsPrice {
                            color: #dc0005;
                        }
                    }
                }
            }
        }
    }
}
</style>
