<template>
  <div class="instantSessionBox">
    <div class="left">
      <el-input
        v-model="searchMan"
        prefix-icon="el-icon-search"
        :input-style="{ height: ' 30px', backgroundColor: '#f2f2f2' }"
        placeholder="搜索最近联系人，聊天记录"
      ></el-input>
      <div class="userTabs">
        <div @click="activeTabs = 0" :class="activeTabs == 0 ? 'active' : ''">
          <div>
            客户列表<span v-if="noReadMsgNum > 0" style="color: red">({{ noReadMsgNum }})</span>
          </div>
        </div>
        <div @click="activeTabs = 1" :class="activeTabs == 1 ? 'active' : ''">
          <div>
            排队列表<span v-if="beAccessedList.length > 0" style="color: red"
              >({{ beAccessedList.length }})</span
            >
          </div>
        </div>
      </div>
      <div class="userList customList" v-show="!Boolean(activeTabs)">
        <div
          :style="{ backgroundColor: activeMan.uid == item.uid ? '#F0F0F0' : '' }"
          class="listItem"
          v-for="(item, index) in allUserList"
          :key="index"
          @click="chooseMan(item)"
        >
          <div class="itemLeft">
            <el-badge
              v-if="item.unread_num > 0"
              :value="item.unread_num"
              class="item"
              type="danger"
            >
              <img :src="item.avatar" alt="" />
            </el-badge>
            <img v-else :src="item.avatar" alt="" />
          </div>
          <div class="itemRight">
            <div class="itemRightTop">
              <span style="color: #333333">{{ item.username }}</span>
              <span style="color: #19b200">{{ getDate(item.time_line, 'chat') }}</span>
            </div>
            <div style="color: #999999" class="itemRightBot">
              {{ item.type == 'image' ? '[图片]' : item.type == 'goods' ? '[商品]' : item.message }}
            </div>
          </div>
        </div>
      </div>
      <div class="userList workList" v-show="Boolean(activeTabs)">
        <div
          :style="{ backgroundColor: activeMan.uid == item.uid ? '#F0F0F0' : '' }"
          class="listItem"
          v-for="(item, index) in beAccessedList"
          :key="index"
          @click="chooseMan(item)"
        >
          <div class="itemLeft">
            <el-badge
              v-if="item.unread_num > 0"
              :value="item.unread_num"
              class="item"
              type="danger"
            >
              <img :src="item.avatar" alt="" />
            </el-badge>
            <img v-else :src="item.avatar" alt="" />
          </div>
          <div class="itemRight">
            <div class="itemRightTop">
              <span style="color: #333333">{{ item.username }}</span>
              <span :style="{ color: item.online == 1 ? '#19b200' : 'red' }">{{
                item.online == 1 ? '在线' : '离线'
              }}</span>
            </div>
            <div style="color: #999999" class="itemRightBot">
              <div class="message">{{ item.message }}</div>
              <div class="custBtnPri" @click="accessUser(item, index)">接入</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mid">
      <div class="midTop">
        <span>{{ activeMan.username }}</span>
        <div>
          <el-popover
            placement="bottom"
            :width="320"
            title="转接客服"
            v-model:visible="chooseTransfer"
          >
            <template #>
              <div class="popTop">
                <el-input
                  prefix-icon="el-icon-search"
                  :input-style="{
                    height: ' 30px',
                    backgroundColor: '#f2f2f2',
                    borderRadius: '15px'
                  }"
                  placeholder="搜索转接客服"
                  size="small"
                ></el-input>
              </div>
              <div class="popMid"></div>
              <div class="fotBigBox">
                <p>已选择***</p>
                <div class="fotBox">
                  <div class="custBtn">取消</div>
                  <div class="custBtnPri">确认</div>
                </div>
              </div>
            </template>
            <template #reference>
              <div class="action">转接</div>
            </template>
          </el-popover>
          <div class="action">结束服务</div>
        </div>
      </div>
      <div class="midMid" ref="midmid" @scroll="chatScroll">
        <template v-for="(item, index) in chatRecordl" :key="index">
          <div
            v-if="item.type != 'dateLine' && item.fromUserFd.uid == userInfo.id"
            :style="{ justifyContent: 'flex-end' }"
            class="chatItem"
          >
            <pre v-if="item.type === 'text'" class="content">{{ item.content }}</pre>
            <div v-if="item.type === 'image'" class="chatImgBox" style="background-color: #98e165">
              <el-image :src="item.content" :preview-src-list="chatImgList"> </el-image>
            </div>
            <div class="goodsBox" v-if="item.type === 'miniprogrampage'">
              <div class="goodsBoxLeft">
                <img :src="item.content.thumb" alt="" />
              </div>
              <div class="goodsBoxRight">
                <div>{{ item.content.title }}</div>
                <div>￥{{ item.content.goods_price }}</div>
              </div>
            </div>
            <div class="headImg">
              <!-- 定位 -->
              <!-- <img :src="item.fromUserFd.avatar" alt="" /> -->
              <img :src="userInfo.avatar" alt="" />
            </div>
          </div>
          <div
            v-if="item.type != 'dateLine' && item.fromUserFd.uid != userInfo.id"
            :style="{ justifyContent: 'flex-start' }"
            class="chatItem"
          >
            <div class="headImg">
              <img :src="item.fromUserFd.avatar" alt="" />
            </div>
            <pre v-if="item.type === 'text'" class="content">{{ item.content }}</pre>
            <div v-if="item.type === 'image'" class="chatImgBox" style="background-color: #fff">
              <el-image :src="item.content" :preview-src-list="chatImgList" :infinite="false">
              </el-image>
            </div>
            <div class="goodsBox" v-if="item.type === 'miniprogrampage'">
              <div class="goodsBoxLeft">
                <img :src="item.content.thumb" alt="" />
              </div>
              <div class="goodsBoxRight">
                <div>{{ item.content.title }}</div>
                <div>￥{{ item.content.goods_price }}</div>
              </div>
            </div>
          </div>
          <div class="timeCenter" v-if="item.type === 'dateLine'">
            {{ detectionTime(item.sendTime) }}
          </div>
        </template>
      </div>
      <div class="midBot">
        <div
          style="height: 100%; display: flex; justify-content: center; align-items: center"
          v-if="activeMan.access_id"
        >
          <div class="">请先接入会话</div>
        </div>
        <div v-else class="one">
          <div class="midBotTop">
            <el-popover
              class="addSome"
              v-model:visible="expressionFlag"
              placement="top"
              width="300px"
              trigger="click"
            >
              <div>
                <div
                  class="emojisList"
                  v-for="(item, index) in expressionList"
                  :key="index"
                  :style="{
                    display: expressionListActive == index ? 'block' : 'none'
                  }"
                >
                  <div>
                    <span
                      @click="insertEmojis(item1)"
                      v-for="(item1, index1) in item.list"
                      :key="index1"
                      >{{ item1 }}</span
                    >
                  </div>
                </div>
                <div class="classStyle">
                  <span
                    @click="expressionListActive = index"
                    v-for="(item, index) in expressionList"
                    :key="index"
                    :style="{
                      background: expressionListActive == index ? '#e7e7e7' : ''
                    }"
                    >{{ item.name }}</span
                  >
                </div>
              </div>
              <template #reference>
                <img :src="imgUrl + 'expression.png'" alt="" />
              </template>
            </el-popover>
            <img class="addSome" :src="imgUrl + 'folder.png'" alt="" />
            <el-popover
              class="addSome"
              v-model:visible="quickReplyFlag"
              placement="top"
              width="540px"
              trigger="click"
            >
              <div class="replyTop">
                <div
                  @click="replyClass = index"
                  v-for="(item, index) in replyType"
                  :key="index"
                  :style="{
                    color: replyClass == index ? '#1467ff' : '',
                    backgroundColor: replyClass == index ? '#fff' : ''
                  }"
                >
                  {{ item.name }}
                </div>
              </div>
              <div class="replyList">
                <div
                  class="listItem"
                  v-for="(item, index) in replyType[replyClass].list"
                  :key="index"
                  @click="chooseReply(item)"
                >
                  <div>
                    {{ item.data }}
                  </div>
                </div>
              </div>

              <template #reference>
                <img class="addSome" :src="imgUrl + 'quickreply.png'" alt="" />
              </template>
            </el-popover>
          </div>
          <el-input
            id="chatInput"
            ref="chatInput"
            @keydown="chatKey"
            @blur="chatContentChange"
            @paste="dealPasteImg"
            type="textarea"
            placeholder="输入消息..."
            v-model.trim="chatContent"
            :spellcheck="false"
          >
          </el-input>
          <div class="sendBtn" @click="sendMsg('text')">发送</div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="rightTop">
        <div
          @click="rightMenuActive = index"
          v-for="(item, index) in rightMenu"
          :key="index"
          :class="rightMenuActive === index ? 'isActive' : ''"
        >
          {{ item.title }}
        </div>
      </div>
      <div class="rightBot">
        <Auxiliaryfunction v-if="rightMenuActive == 0"></Auxiliaryfunction>
        <Productlink
          v-if="rightMenuActive == 1"
          :userInfo="userInfo"
          @sendGoodsMsg="sendMsg"
        ></Productlink>
        <Chatrecord v-if="rightMenuActive == 2"></Chatrecord>
      </div>
    </div>
  </div>
  <el-dialog title="上传图片" v-model="pasteDialogFlag" width="50%" center @close="closeUploadImg">
    <div style="display: flex; flex-direction: column; align-items: center">
      <template v-for="(item, index) in pasteImgSrc" :key="index">
        <img style="max-width: 100%; flex-shrink: 0; margin-bottom: 10px" :src="item" alt="" />
      </template>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="pasteDialogFlag = false">取 消</el-button>
        <el-button type="primary" @click="sureUpload">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { imgUrl } from '@/util/baseUrl.js'
import { expressionList } from '@/util/emojis.js'
import { ref, toRefs, reactive, nextTick, watch, inject, onUpdated } from 'vue'
import { useRoute } from 'vue-router'
import {
  getDate,
  detectionTime,
  addChatList,
  getMaxKey,
  inputInsert,
  dataURItoBlob,
  dealStorageChat
} from '../../util/common'
import Auxiliaryfunction from './components/Auxiliaryfunction.vue'
import Productlink from './components/Productlink.vue'
import Chatrecord from './components/Chatrecord.vue'
import { post, uploadFile } from '@/util/axios'
import { api } from '@/util/api'
import { ElMessage } from 'element-plus'
import _ from 'lodash'
export default {
  components: {
    Auxiliaryfunction,
    Productlink,
    Chatrecord
  },

  setup(props, content) {
    let socketAll = inject('socketAll')
    const userInfo = JSON.parse(sessionStorage.getItem('info'))
    let newMsg = inject('newMsg')
    let data = reactive({
      // 搜索联系人
      searchMan: null,
      // 选中列表
      activeTabs: 0,
      // 选中联系人
      activeMan: { access_id: 1 },
      expressionListActive: 0,
      // 存储聊天记录
      chatRecordl: [],
      // 聊天框里输入的内容
      chatContent: '',
      // 快捷回复类型
      replyType: [
        {
          id: 0,
          name: '售前',
          list: [{ id: 1, data: '我是售前，如果觉得很不错的话，可以直接下单哦！' }]
        },
        {
          id: 2,
          name: '售中',
          list: [
            { id: 1, data: '我是售中，如果觉得很不错的话，可以直接下单哦！' },
            {
              id: 2,
              data: '山东，本地产当季水果，百分之百大果，品质保障！山东，本地产当季水果，百分之百大果，品质保障！ 山东，本地产当季水果，百分之百大果，品质保障！ 山东，本地产当季水果，百分之百大果，品质保…山东，本地产当季水果，百分之百大果，品质保障！山东，本地产当季水果，百分之百大果，品质保障！ 山东，本地产当季水果，百分之百大果，品质保障！ 山东，本地产当季水果，百分之百大果，品质保…'
            }
          ]
        }
      ],
      // 被选中的快捷回复类型
      replyClass: 0,
      // 右侧顶部菜单
      rightMenu: [{ title: '辅助功能' }, { title: '商品链接' }, { title: '聊天记录' }],
      // 右侧顶部被选中的菜单
      rightMenuActive: 0,
      // 是否开启转接
      chooseTransfer: false,
      // 是否是选择客户
      isClickCustom: false
    })
    onUpdated(() => {
      if (data.isClickCustom) {
        setTimeout(() => {
          midmid.value.scrollTop = midmid.value.scrollHeight
          data.isClickCustom = false
        }, 50)
      }
    })

    // 开启选择表情包
    let expressionFlag = ref(false)
    // 开启选择快捷回复
    let quickReplyFlag = ref(false)
    const chatImgList = reactive([])
    const {
      chatContentChange,
      insertEmojis,
      sendMsg,
      chatKey,
      chatInput,
      inputInsert,
      storeRange,
      midmid,
      chooseReply,
      dealPasteImg,
      pasteImgSrc,
      pasteDialogFlag,
      closeUploadImg,
      sureUpload,
      chatScroll
    } = chat(data, expressionFlag, quickReplyFlag, socketAll, userInfo, chatImgList)
    // 获取客服列表
    let allUserList = ref([])
    let noReadMsgNum = ref(0)
    const getServiceList = () => {
      post(api.all_users, {}).then((res) => {
        if (res.code === 0) {
          if (res.result) {
            allUserList.value = res.result
          } else {
            allUserList.value = []
          }
          allUserList.value.map((item) => {
            noReadMsgNum.value += item.unread_num
          })
        }
      })
    }
    getServiceList()
    // 获取待接入列表
    let beAccessedList = ref([])
    const getbeAccessedList = () => {
      post(api.already_users, {}).then((res) => {
        if (res.code === 0) {
          if (res.result) {
            beAccessedList.value = res.result
          } else {
            beAccessedList.value = []
          }
        }
      })
    }
    getbeAccessedList()

    // 选中聊天框
    const chooseMan = (item) => {
      data.isClickCustom = true
      data.activeMan = item
      data.chatRecordl = []
      // 获取这两个账号的聊天记录
      if (localStorage.getItem('chatList')) {
        if (JSON.parse(localStorage.getItem('chatList'))[`${userInfo.id}`]) {
          if (JSON.parse(localStorage.getItem('chatList'))[`${userInfo.id}`][`${item.uid}`]) {
            // 这两个账号的聊天记录
            let withChatList = JSON.parse(localStorage.getItem('chatList'))[`${userInfo.id}`][
              `${item.uid}`
            ]
            let withMaxKey = getMaxKey(withChatList)
            nextTick(() => {
              midmid.value.scrollTop = midmid.value.scrollHeight
            })
            if (withChatList[`${withMaxKey}`].length >= 10) {
              //
              for (let i = 0; i < 10; i++) {
                let nowItem =
                  withChatList[`${withMaxKey}`][withChatList[`${withMaxKey}`].length - 1 - i]
                if (nowItem.type === 'image') {
                  chatImgList.push(nowItem.content)
                }
                data.chatRecordl.unshift(nowItem)
              }
            } else {
              // 一天的聊天记录不够十条
              let num = 10
              do {
                for (let i = 0; i < withChatList[`${withMaxKey}`].length; i++) {
                  num--
                  let nowItem =
                    withChatList[`${withMaxKey}`][withChatList[`${withMaxKey}`].length - 1 - i]
                  if (nowItem.type === 'image') {
                    chatImgList.push(nowItem.content)
                  }
                  data.chatRecordl.unshift(nowItem)
                  if (num === 0) break
                }
                if (withMaxKey === 1) break
                withMaxKey--
              } while (num > 0)
            }
          }
        }
      }
      // 点击的已接入的会话
      if (!data.activeMan.access_id) {
        // 判断是否需要重新接入客户
        let isNeed = beAccessedList.value.find((item1) => item1.uid === item.uid)
        if (isNeed !== undefined) {
          item.access_id = isNeed.access_id
        } else {
          nextTick(() => {
            chatInput.value.$el.firstElementChild.focus()
          })
        }
        // 如果有未读消息就通知后台将该聊天的未读消息清空
        if (item.unread_num > 0) {
          let obj = {
            controller: 'broadcast',
            action: 'handleMessage',
            params: { service_id: userInfo.id, type: 'out_room', client_id: item.uid }
          }
          socketAll.value.send(JSON.stringify(obj))
          noReadMsgNum.value = noReadMsgNum.value - item.unread_num
          item.unread_num = 0
        }
      }
    }
    // 点击接入用户
    const accessUser = (item, index) => {
      if (sessionStorage.getItem('outLine')) {
        ElMessage.warning('离线状态不可以接入客户')
        return
      }
      post(api.accessUser, {
        user_id: item.uid,
        access_id: item.access_id
      }).then((res) => {
        if (res.code === 0) {
          ElMessage.success(`接入成功`)
          data.activeTabs = 0
          let thisItem = allUserList.value.find((item1) => item1.uid == item.uid)
          if (thisItem === undefined) {
            allUserList.value.unshift(item)
          } else {
            delete thisItem.access_id
            thisItem.message = item.message
            thisItem.unread_num = 0
            thisItem.time_line = item.time_line
          }
          beAccessedList.value.splice(index, 1)
          delete item.access_id
          chooseMan(item)
        } else {
          ElMessage.error(`${res.msg}`)
        }
      })
    }
    let prevMsg = null
    watch(
      newMsg,
      (val) => {
        if (val && val.action === 104) {
          // 储存时间戳
          if (prevMsg && val.sendTime - prevMsg.sendTime >= 2 * 60) {
            data.chatRecordl.push({ sendTime: val.sendTime, type: 'dateLine' })
          }
          // 新的聊天消息
          if (data.activeMan.uid) {
            // 处理收到消息时就在这个聊天框的情况
            if (val.fromUserFd.uid === data.activeMan.uid) {
              let obj = {
                controller: 'broadcast',
                action: 'handleMessage',
                params: {
                  service_id: userInfo.id,
                  type: 'on_room',
                  client_id: data.activeMan.uid,
                  message_id: val.message_id
                }
              }
              if (!data.activeMan.access_id) {
                socketAll.value.send(JSON.stringify(obj))
              }
              data.chatRecordl.push(val)
              nextTick(() => {
                midmid.value.scrollTop = midmid.value.scrollHeight
              })
            }
          }
          let acceptMsg = allUserList.value.find((item) => item.uid === val.fromUserFd.uid)
          if (val.access_type === 'on_access') {
            // 未接入消息
            let isHave = beAccessedList.value.find((item) => item.uid === val.fromUserFd.uid)
            if (isHave) {
              // 列表中有该客户的未接入消息
              isHave.message = val.content
              isHave.unread_num++
              isHave.time_line = val.sendTime
              // 判断已接入的客户里面有没有此客户
              if (acceptMsg) {
                acceptMsg.time_line = val.sendTime
                if (val.type == 'text') {
                  acceptMsg.message = val.content
                } else if (val.type == 'image') {
                  acceptMsg.message = '[图片]'
                } else if (val.type == 'miniprogrampage') {
                  acceptMsg.message = '[商品]'
                }
              }
              if (val.type == 'text') {
                isHave.message = val.content
              } else if (val.type == 'image') {
                isHave.message = '[图片]'
              } else if (val.type == 'miniprogrampage') {
                isHave.message = '[商品]'
              }
            } else {
              // 列表中没有该客户的未接入消息
              beAccessedList.value.unshift({
                avatar: val.fromUserFd.avatar,
                username: val.fromUserFd.username,
                uid: val.fromUserFd.uid,
                time_line: val.sendTime,
                online: 1,
                message: val.content,
                unread_num: 1,
                access_id: val.access_id,
                access_type: val.access_type,
                type: val.type
              })
              if (val.type == 'text') {
                beAccessedList.value[0].message = val.content
              } else if (val.type == 'image') {
                beAccessedList.value[0].message = '[图片]'
              } else if (val.type == 'miniprogrampage') {
                beAccessedList.value[0].message = '[商品]'
              }
            }
          } else {
            // 已接入消息
            if (data.activeMan.uid === val.fromUserFd.uid) {
              // 当前在该客户的聊天框
              acceptMsg.unread_num = 0
              acceptMsg.time_line = val.sendTime
            } else {
              acceptMsg.unread_num++
              noReadMsgNum.value++
              acceptMsg.time_line = val.sendTime
            }
            if (val.type == 'text') {
              acceptMsg.message = val.content
            } else if (val.type == 'image') {
              acceptMsg.message = '[图片]'
            } else if (val.type == 'miniprogrampage') {
              acceptMsg.message = '[商品]'
            }
          }
        }
        prevMsg = val
      },
      {
        deep: true,
        immediate: true
      }
    )
    watch(
      expressionFlag,
      (val) => {
        if (val) {
          chatInput.value.$el.firstElementChild.focus()
          chatInput.value.$el.firstElementChild.selectionStart = storeRange.start
          chatInput.value.$el.firstElementChild.selectionEnd = storeRange.end
        }
      },
      {}
    )
    const $route = useRoute()
    // 聊天页面加载时默认聊天框在最底部
    watch(
      $route,
      (val) => {
        if (val.path === '/instantSession') {
          nextTick(() => {
            midmid.value.scrollTop = midmid.value.scrollHeight
          })
        }
      },
      {
        immediate: true
      }
    )
    return {
      // 获取已接入的列表
      getServiceList,
      // 获取带接入客服列表
      getbeAccessedList,
      // 用户信息
      userInfo,
      // 储存的变量
      ...toRefs(data),
      // 图片地址域名及路径
      imgUrl,
      // 打开表情包选项
      expressionFlag,
      // 插入表情包
      insertEmojis,
      // 表情包
      expressionList,
      // 聊天框内容变化监听
      chatContentChange,
      // 发送消息
      sendMsg,
      // 聊天框的键盘监听事件
      chatKey,
      // 聊天框的dom结构
      chatInput,
      // 聊天框插入
      inputInsert,
      // 聊天界面dom
      midmid,
      // 开启快捷回复弹框
      quickReplyFlag,
      // 选择快捷回复
      chooseReply,
      // 全部在线客户
      allUserList,
      // 处理时间戳
      getDate,
      detectionTime,
      // 处理当前选中的客户信息
      chooseMan,
      // 待接入客户列表
      beAccessedList,
      // 已接入客户的纬度消息数
      noReadMsgNum,
      // 接入客户
      accessUser,
      // 处理输入框粘贴事件
      dealPasteImg,
      // 粘贴图片的base64
      pasteImgSrc,
      // 粘贴图片弹框
      pasteDialogFlag,
      // 关闭粘贴弹框回调
      closeUploadImg,
      // 确认上传剪切板的图片
      sureUpload,
      // 显示聊天记录框滑动触发
      chatScroll,
      chatImgList
    }
  }
}
// 输入框有关操作  setup中的data数据 表情包选择器开关 快捷回复选择器开关
const chat = (data, expressionFlag, quickReplyFlag, socketAll, userInfo, chatImgList) => {
  const chatInput = ref(null)
  const midmid = ref(null)

  const chatData = reactive({
    storeRange: { start: 1, end: 1 }
  })
  // 检测输入框文字变化
  const chatContentChange = () => {
    chatData.storeRange.start = chatInput.value.$el.firstElementChild.selectionStart
    chatData.storeRange.end = chatInput.value.$el.firstElementChild.selectionEnd
  }
  // 点击发送消息
  const sendMsg = (type, path) => {
    if (sessionStorage.getItem('outLine')) {
      ElMessage.warning('离线状态不可以发消息')
      return
    }
    if (!data.activeMan.uid) {
      ElMessage.warning('请选择聊天对象')
      return
    }
    if (!data.activeMan.access_type === 'on_access' || data.activeMan.access_id) {
      ElMessage.warning('请先接入客户')
      return
    }
    if (type == 'text') {
      if (data.chatContent == null || data.chatContent == '') {
        ElMessage.warning('请输入聊天内容')
        return
      }
    }
    let obj = {
      controller: 'broadcast',
      action: 'personBroadcast',
      fromtype: 'uniacid',
      params: {
        content:
          type == 'text'
            ? data.chatContent
            : type == 'image'
            ? path
            : type == 'miniprogrampage'
            ? { goodsid: path.id }
            : '',
        msgtype: type,
        from: userInfo.id,
        to: data.activeMan.uid
      }
    }

    socketAll.value.send(JSON.stringify(obj))
    let nowDate = new Date().getTime() / 1000
    let chatObj = dealStorageChat(
      userInfo,
      data.activeMan,
      type == 'text'
        ? data.chatContent
        : type == 'image'
        ? path
        : type == 'miniprogrampage'
        ? {
            title: path.goods_name,
            thumb: path.goods_picture,
            goods_price: path.goods_price
          }
        : '',
      type
    )
    if (
      data.chatRecordl.length > 1 &&
      nowDate - data.chatRecordl[`${data.chatRecordl.length - 1}`].sendTime > 5 * 60
    ) {
      data.chatRecordl.push({ sendTime: nowDate, type: 'dateLine' })
    }
    data.chatRecordl.push(chatObj)
    data.activeMan.time_line = nowDate
    if (type == 'text') {
      data.activeMan.message = data.chatContent
    } else if (type == 'image') {
      data.activeMan.message = '[图片]'
    } else if (type == 'miniprogrampage') {
      data.activeMan.message = '[商品]'
    }
    let localList = ref({})
    localList.value = JSON.parse(localStorage.getItem('chatList'))
      ? JSON.parse(localStorage.getItem('chatList'))
      : {}
    addChatList(localList, chatObj)
    data.chatContent = ''
    nextTick(() => {
      midmid.value.scrollTop = midmid.value.scrollHeight
    })
  }
  // 插入表情包
  const insertEmojis = (item) => {
    let { content, callback } = inputInsert(item, chatInput, data.chatContent, chatData.storeRange)
    data.chatContent = content
    nextTick(() => {
      callback()
    })
    expressionFlag.value = false
  }
  // 输入框的键盘监听事件
  const chatKey = (e) => {
    if (e.keyCode == 13 && e.ctrlKey) {
      e.preventDefault()
      let { content, callback } = inputInsert(
        null,
        chatInput,
        data.chatContent,
        chatData.storeRange
      )
      data.chatContent = content
      nextTick(() => {
        callback()
      })
    } else if (e.keyCode == 13) {
      e.preventDefault()
      // 回车
      sendMsg('text')
    }
  }
  // 选中快捷回复
  const chooseReply = (item) => {
    data.chatContent = item.data
    quickReplyFlag.value = false
    chatInput.value.$el.firstElementChild.focus()
  }
  // 监听粘贴事件
  let pasteImgSrc = ref([])
  let pasteDialogFlag = ref(false)
  const dealPasteImg = (event) => {
    if (!data.activeMan.uid) {
      ElMessage.warning('请选择聊天对象')
      event.preventDefault()
      return
    }
    var items = (event.clipboardData || event.originalEvent.clipboardData).items
    if (items.length > 0 && items[0].kind === 'file') {
      items.forEach((element, index) => {
        dealBase64(element, index)
      })
      pasteDialogFlag.value = true
    }
  }
  const dealBase64 = (element, index) => {
    let blob = element.getAsFile()
    let reader = new FileReader()
    reader.onload = function (e) {
      pasteImgSrc.value[index] = e.target.result
    }
    reader.readAsDataURL(blob)
  }
  const closeUploadImg = () => {
    pasteImgSrc.value = []
  }
  const uploadChatFile = (fileList) => {
    let index = 0
    const uploadItem = async () => {
      return await uploadFile(api.addImage, fileList[index]).then((res) => {
        if (res.code !== 0) {
          ElMessage.error(`第${index + 1}张图片上传失败`)
        }
        // 在这处理图片上传成功后的操作
        sendMsg('image', res.result.path)
        if (index < fileList.length - 1) {
          index++
          uploadItem()
        } else {
          ElMessage.success(`上传完毕`)
          pasteDialogFlag.value = false
        }
      })
    }
    uploadItem()
  }
  const sureUpload = () => {
    let fileList = []
    pasteImgSrc.value.map((item) => {
      let blod = dataURItoBlob(item)
      let files = new window.File([blod], 'chatImg.png', { type: 'image/png' })
      let formData = new FormData()
      formData.append('image', files)
      fileList.push(formData)
    })
    uploadChatFile(fileList)
  }
  let lastMigHeigt = 0
  const chatScroll = () => {
    if (midmid.value.scrollTop === 0) {
      //  上滑加载聊天记录 ->触发该方法时本地必然有这两个人的聊天记录
      let chatListThere = reactive(
        JSON.parse(localStorage.getItem('chatList'))[`${userInfo.id}`][`${data.activeMan.uid}`]
      )
      let maxKey = getMaxKey(chatListThere)
      let nowDay = maxKey
      let nowIndex = -1
      for (let i = 0; i < maxKey; i++) {
        nowIndex = chatListThere[`${maxKey - i}`].findIndex(
          (item) => JSON.stringify(item) === JSON.stringify(data.chatRecordl[0])
        )
        nowDay = maxKey - i
        if (nowIndex != -1) break
      }
      let num = 9
      if (nowIndex > num) {
        for (let i = 0; i < num; i++) {
          let nowItem = chatListThere[nowDay][nowIndex - i - 1]
          if (nowItem.type === 'image') {
            chatImgList.push(nowItem.content)
          }
          data.chatRecordl.unshift(nowItem)
        }
      } else {
        for (let i = 0; i < nowIndex; i++) {
          let nowItem = chatListThere[nowDay][nowIndex - i - 1]
          if (nowItem.type === 'image') {
            chatImgList.push(nowItem.content)
          }
          data.chatRecordl.unshift(nowItem)
        }
        if (nowIndex !== 0 && nowDay !== 1) {
          let overThat = num - nowIndex // 算出加载完这天的聊天记录还需要加载几条
          nowDay-- // 来到前一天
          do {
            for (let i = 0; i < chatListThere[`${nowDay}`].length; i++) {
              let nowItem = chatListThere[`${nowDay}`][chatListThere[`${nowDay}`].length - 1 - i]
              if (nowItem.type === 'image') {
                chatImgList.push(nowItem.content)
              }
              overThat--
              data.chatRecordl.unshift(nowItem)
              if (overThat === 0) break
            }
            if (nowDay === 1) return
            nowDay--
          } while (overThat > 0)
        }
      }
      // 保持滚动条
      midmid.value.scrollTop = midmid.value.scrollHeight - lastMigHeigt
      lastMigHeigt = midmid.value.scrollHeight
    }
  }
  return {
    ...chatData,
    chatContentChange,
    insertEmojis,
    sendMsg,
    chatKey,
    chatInput,
    midmid,
    chooseReply,
    dealPasteImg,
    pasteImgSrc,
    pasteDialogFlag,
    closeUploadImg,
    sureUpload,
    chatScroll
  }
}
</script>

<style lang="less" scoped>
.flexLayout(@direction:row, @main:space-between, @second:center) {
  display: flex;
  flex-direction: @direction;
  justify-content: @main;
  align-items: @second;
}
.instantSessionBox {
  width: 100%;
  height: 100%;
  .flexLayout(row,flex-start,flex-start);
  & > div {
    height: 100%;
  }
  .left {
    flex: 0 0 282px;
    border-right: 1px solid #eaeaea;
    .flexLayout(column,flex-start);
    .el-input {
      width: 260px;
      margin-top: 13px;
      height: 30px;
      line-height: 30px;
      background-color: #f2f2f2;
      :deep(.el-input__icon) {
        line-height: 30px;
      }
      :deep(.el-input__inner:focus) {
        border-color: #dcdfe6;
      }
    }
    .userTabs {
      margin-top: 10px;
      width: 100%;
      .flexLayout(row, space-around);
      & > div > div {
        width: 56px;
        padding: 14px 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        white-space: nowrap;
      }
      & > .active {
        color: #1467ff;
        position: relative;
        &::after {
          content: '';
          display: block;
          width: 100%;
          height: 2px;
          background-color: #1467ff;
          position: absolute;
          bottom: -2px;
          left: 0;
        }
      }
    }
    .userList {
      margin-top: 2px;
      width: 100%;
      height: calc(100% - 95px);
      overflow: hidden;
      overflow-y: auto;
      .listItem {
        width: 100%;
        height: 80px;
        padding: 14px 20px;
        .flexLayout(row,flex-start);
        .itemLeft {
          margin-right: 18px;
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
        .itemRight {
          width: 100%;
          height: 100%;
          .flexLayout(column, space-around,space-start);
          .itemRightTop {
            .flexLayout();
          }
          .itemRightBot {
            width: 183px;
            height: 20px;
            flex-shrink: 0;
            line-height: 20px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .message {
              width: 60%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .custBtnPri {
              width: 30%;
              height: 20px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
  .mid {
    flex: 1;
    .flexLayout(column,flex-start);
    .midTop {
      width: 100%;
      flex: 0 0 48px;
      border-bottom: 1px solid #eaeaea;
      .flexLayout();
      padding: 0 10px 0 23px;
      & > div {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
      }
      .action {
        margin-left: 24px;
        cursor: pointer;
        color: #1467ff;
        white-space: nowrap;
      }
    }
    .midMid {
      width: 100%;
      padding-left: 20px;
      flex: 1;
      overflow: hidden;
      overflow-y: auto;
      background-color: #fafafa;
      .flexLayout(column, flex-start);
      .timeCenter {
        padding: 0 10px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        background-color: rgba(204, 204, 204, 0.6);
        color: #fff;
        border-radius: 10px;
        user-select: none;
      }
      .chatItem {
        .chatImgBox {
          max-width: 80%;
          padding: 10px 8px;
          border-radius: 4px;
          margin: 0 10px;
          .el-image {
            width: 100%;
          }
        }
        padding-right: 20px;
        margin: 10px 0;
        width: 100%;
        margin-bottom: 24px;
        .flexLayout(row,flex-start,flex-start);
        .goodsBox {
          display: flex;
          align-items: center;
          background-color: #fff;
          height: 100%;
          padding: 10px;
          border-radius: 4px;
          margin: 0 10px;
          .goodsBoxLeft {
            img {
              width: 57px;
              height: 57px;
              border-radius: 4px;
              vertical-align: bottom;
            }
          }
          .goodsBoxRight {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 10px;
            & > div:first-child {
              width: 220px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            & > div:last-child {
              color: red;
            }
          }
        }
        .content {
          background: #98e165;
          padding: 8px;
          white-space: pre-wrap;
          max-width: 400px;
          padding: 10px 8px;
          background: #fff;
          margin: 0 10px;
          line-height: 18px;
          letter-spacing: 1px;
          word-wrap: break-word;
          border-radius: 4px;
        }
        .headImg {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
    .midBot {
      width: 100%;
      flex: 0 0 270px;
      border-top: 1px solid #eaeaea;
      flex-shrink: 0;
      .one {
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
      }
      .midBotTop {
        height: 48px;
        .flexLayout(row,flex-start);
        border-bottom: 1px solid #eaeaea;
        img {
          margin-left: 21px;
          user-select: none;
          cursor: pointer;
        }
      }
      .el-textarea {
        height: calc(100% - 48px);
        border: none;
        overflow: hidden;
        overflow-y: auto;
        :deep(.el-textarea__inner) {
          padding: 10px 0 10px 20px;
          border: none;
          resize: none;
          height: 100%;
          margin-right: 20px;
          max-height: 400px;
          &::-webkit-scrollbar {
            width: 5px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            background: rgba(0, 0, 0, 0.2);
          }
          &::-webkit-scrollbar-track {
            border-radius: 0;
            background: rgba(0, 0, 0, 0);
          }
        }
      }
      .sendBtn {
        align-self: flex-end;
        width: 87px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background: #f3f3f3;
        border: 1px solid #f8f8f8;
        border-radius: 2px;
        margin-bottom: 20px;
        margin-right: 20px;
        cursor: pointer;
        user-select: none;
      }
    }
  }
  .right {
    flex: 0 0 540px;
    height: 100%;
    border-left: 1px solid #eaeaea;
    .rightTop {
      height: 48px;
      border-bottom: 1px solid #e8e8e8;
      padding: 0 58px 0 55px;
      .flexLayout(row);
      & > div {
        height: 100%;
        line-height: 48px;
        position: relative;
        cursor: pointer;
      }
      .isActive {
        color: #1467ff;
        &::after {
          content: '';
          display: inline-block;
          width: 100%;
          height: 1px;
          position: absolute;
          bottom: -1px;
          left: 0;
          background-color: #1467ff;
        }
      }
    }
    .rightBot {
      height: calc(100% - 48px);
    }
  }
}
.emojisList {
  width: 100%;
  height: 200px;
  overflow: hidden;
  overflow-y: auto;
  div {
    .flexLayout(row,flex-start,flex-start);
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
  }
  span {
    display: block;
    width: 49px;
    height: 40px;
    cursor: pointer;
    margin: 0 5px 5px 0;
    font-size: 30px;
    user-select: none;
    text-align: center;
  }
  &::-webkit-scrollbar {
    width: 0;
  }
}
.el-popover .classStyle {
  padding-top: 12px;
  span {
    display: inline-block;
    padding: 5px 4px;
    font-size: 12px;
    margin-right: 10px;
    cursor: pointer;
    user-select: none;
  }
}
.replyTop {
  .flexLayout(row,flex-start);
  & > div {
    flex: 1;
    text-align: center;
    cursor: pointer;
    height: 55px;
    line-height: 55px;
    background-color: #f5f5f5;
  }
  & > div:hover {
    background-color: #f9f9f9;
  }
}
.replyList {
  height: 370px;
  background-color: #fff;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
  .listItem {
    padding: 20px 50px 20px 20px;
    cursor: pointer;
    .flexLayout(row,flex-start);
    div {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
    &::before {
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #1467ff;
      margin-right: 4px;
      flex-shrink: 0;
    }
  }
  .listItem:hover {
    background-color: #f9f9f9;
  }
}
.popTop {
  display: flex;
  justify-content: center;
  align-items: center;
  :deep(.el-input) {
    border-color: #f2f2f2;
  }
  :deep(.el-input__inner):focus {
    border-color: #f2f2f2;
  }
}
.fotBigBox {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fotBox {
  display: flex;
  justify-content: flex-end;
  & > div {
    width: 60px;
    height: 34px;
    line-height: 34px;
  }
  .custBtn {
    margin-right: 10px;
  }
}
</style>
