<template>
    <!-- 辅助功能 -->
    <div class="midBox">
        <div class="midTop"><img :src="imgUrl + 'asking.png'" alt="" />一键提问</div>
        <el-input type="textarea" placeholder="请输入你想要提问的内容..." v-model="askContent">
        </el-input>
        <div class="midBot">
            <div class="custBtn" @click="askContent = ''">清空</div>
            <div class="custBtnPri">提交</div>
        </div>
        <div class="xian"></div>
    </div>
    <div class="botBox">
        <div class="botTop"><img :src="imgUrl + 'answercard.png'" alt="" />智慧解答</div>
        <div class="botBot">
            <div class="itemBox" v-for="(item, index) in answerList" :key="index">
                <div>{{ item.data }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { imgUrl } from '@/util/baseUrl.js'
export default {
    setup() {
        const data = reactive({
            askContent: '',
            answerList: [
                { data: '山东，本地产当季水果，百分之百大果，品质保障！' },
                {
                    data: '山东，本地产当季水果，百分之百大果，品质保障！山东，本地产当季水果，百分之百大果，品质保障！ 山东，本地产当季水果，百分之百大果，品质保障！ 山东，本地产当季水果，百分之百大果，品质保…'
                },
                {
                    data: '山东，本地产当季水果，百分之百大果，品质保障！山东，本地产当季水果，百分之百大果，品质保障！ 山东，本地产当季水果，百分之百大果，品质保障！ 山东，本地产当季水果，百分之百大果，山东，本地产当季水果，百分之百大果，品质保障！山东，本地产当季水果，百分之百大果，品质保障！ 山东，本地产当季水果，百分之百大果，品质保障！ 山东，本地产当季水果，百分之百大果，品质保…'
                },
                {
                    data: '山东，本地产当季水果，百分之百大果，品质保障！山东，本地产当季水果，百分之百大果，品质保障！ 山东，本地产当季水果，百分之百大果，品质保障！ 山东，本地产当季水果，百分之百大果，品质保…'
                },
                {
                    data: '山东，本地产当季水果，百分之百大果，品质保障！山东，本地产当季水果，百分之百大果，品质保障！ 山东，本地产当季水果，百分之百大果，品质保障！ 山东，本地产当季水果，百分之百大果，品质保…'
                }
            ]
        })
        return {
            imgUrl,
            ...toRefs(data)
        }
    }
}
</script>

<style lang="less" scoped>
.midBox {
    height: 277px;
    padding: 32px 25px 20px 28px;
    .midTop {
        display: flex;
        align-items: center;
        color: #333;
        img {
            margin-right: 5px;
        }
    }
    .el-textarea {
        height: 152px !important;
        margin-top: 12px;
        :deep(.el-textarea__inner) {
            border-color: #ececec !important;
            resize: none;
            height: 152px !important;
        }
    }
    .midBot {
        margin-top: 14px;
        margin-bottom: 17px;
        display: flex;
        justify-content: flex-end;
        .custBtnPri {
            margin-left: 20px;
        }
    }
    .xian {
        width: 100%;
        height: 1px;
        background-color: #f0f0f0;
    }
}
.botBox {
    height: calc(100% - 277px);
    padding-top: 23px;
    .botTop {
        display: flex;
        align-items: center;
        color: #333;
        padding-left: 22px;
        margin-bottom: 11px;
        img {
            margin-right: 5px;
        }
    }
    .botBot {
        height: calc(100% - 50px);
        overflow: hidden;
        overflow-y: auto;
        .itemBox {
            padding: 17px 32px 17px 24px;
            cursor: pointer;
            display: flex;
            align-items: center;
            div {
                font-size: 14px;
                line-height: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
            }
            &::before {
                content: '';
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: #1467ff;
                margin-right: 4px;
                flex-shrink: 0;
            }
        }
        .itemBox:hover {
            background-color: #f9f9f9;
        }
    }
}
</style>
